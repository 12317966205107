<template>
    <modal
        title="Update SIMs Prices (excl. VAT)"
        ref="priceSimsModal"
        :size="'modal-xl'"
        :disable-save="rowPrice ? rowPrice.length == 0 : false"
        @save="saveSimsPrice"
    >
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Price</th>
                    <th>Per SIM</th>
                    <th>Valid From</th>
                    <th>Valid Until</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="price in rowPrice" :key="price.objkey">
                    <td class="wd-md">
                        <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ getCurrency() }}</span>
                        </div>
                        <input type="text" v-model="price.priceExclVat" class="form-control"/>
                        </div>
                    </td>
                    <td class="wd-md">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ getCurrency() }}</span>
                            </div>
                            <input type="text" v-model="price.perSimPriceExclVat" class="form-control"/>
                        </div>
                    </td>
                    <td>{{ prettifyBillingPeriod(price.validFrom) }}</td>
                    <td>{{ prettifyBillingPeriod(price.validUntil) }}</td>
                </tr>
                <tr>
                    <td class="wd-md">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ getCurrency() }}</span>
                            </div>
                            <input type="text" v-model="newPrice.priceExclVat" class="form-control"/>
                        </div>
                    </td>
                    <td class="wd-md">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ getCurrency() }}</span>
                            </div>
                            <input type="text" v-model="newPrice.perSimPriceExclVat" class="form-control"/>
                        </div>
                    </td>
                    <td>
                        <v-select
                            :options="periods"
                            :get-option-label="getLabel"
                            v-model="newPrice.validFrom"
                            placeholder="Select Period"
                        >
                        </v-select>
                    </td>
                    <td>
                        <button :disabled="newPrice.validFrom == 0" type="button" @click="$emit('addSimsPrice', newPrice)" class="btn btn-info">
                            Add
                        </button>
                        <button class="btn btn-link" type="button" @click="resetSimsPricing()">
                            Reset Unused Pricing
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
    components: {
        Modal
    },
    props: {
        rowPrice: {
            type: Array,
            required: true,
        },
        newPrice: {
            type: Object,
            required: true,
        },
        currency: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            periods: [],
        };
    },
    watch: {
        rowPrice(price) {
            if (price.length > 0) {
                const period = price[price.length - 1].validFrom;
                if (period >= this.getCurrentPeriod()) {
                    this.getPeriods(period);
                } else {
                    this.getPeriods();
                }
            } else {
                this.getPeriods();
            }
        },
    },
    methods: {
        open: function() {
            this.$refs.priceSimsModal.open();
        },
        saveSimsPrice: function() {
            this.$refs.priceSimsModal.isLoading();
            const hostUrl = this.$config.aimsAPI;
            console.log('save');
            this.$http.post(`${hostUrl}pricing/pricepackages/prices/save`, this.rowPrice).then(
                () => {
                    this.showSuccess("Successfully Saved SIMs Prices");
                    this.$emit("pricesAdded");
                    this.$refs.priceSimsModal.close();
                },
                (error) => {
                    this.showError("Error Saving SIMs Pricing", error);
                    console.error(error);
                }
            )
            .finally(() => {
                this.$refs.priceSimsModal.isLoading();
            });
        },
        getPeriods: function(period) {
            const hostUrl = this.$config.aimsAPI;
            let subURL = "system/billingperiods?max=12";

            if (period) {
                subURL += "&period=" + period;
            }

            this.$http.get(`${hostUrl}${subURL}`).then(
                (response) => {
                    this.periods = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching billing periods", error);
                }
            );
        },
        getLabel(period) {
            return this.prettifyBillingPeriod(period);
        },
        resetSimsPricing: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$swal({
                title: "Reset Pricing?",
                text: "This will delete all FUTURE dated packages?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.$refs.priceSimsModal.isLoading();
                    this.$http.post(`${hostUrl}pricing/pricepackages/prices/reset`, this.rowPrice).then(
                        () => {
                            this.showSuccess("Successfully Reset Prices");
                            this.$emit("pricesAdded");
                            this.$refs.priceSimsModal.close();
                        },
                        (error) => {
                            this.showError("Error Resetting Pricing", error);
                            console.error(error);
                        }
                    )
                    .finally(() => {
                        this.$refs.priceSimsModal.isLoading();
                    });
                }
            });
        },
        getCurrency: function() {
            if (this.currency) {
                return this.currency;
            } else return "R";
        },
    }
}
</script>

<style lang="scss" scoped>

</style>