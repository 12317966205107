<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Service Type(s)</label>
          <multiselect
            v-model="selectedClassificationTypes"
            placeholder="Select Service Type(s)"
            @input="typesChanged()"
            group-label="label"
            group-values="values"
            :group-select="true"
            :options="classificationTypes"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="form-group">
          <label>Vendor(s)</label>
          <multiselect
            v-model="selectedClassificationVendors"
            placeholder="Select Vendor(s)"
            @input="
            vendorsChanged()"
            group-label="label"
            group-values="values"
            :group-select="true"
            :disabled="!selectedClassificationTypes || selectedClassificationTypes.length == 0"
            :options="classificationVendors"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="form-group">
          <label>Segment(s)</label>
          <multiselect
            v-model="selectedClassificationSegments"
            placeholder="Select Segment(s)"
            @input="segmentsChanged()"
            group-label="label"
            group-values="values"
            :group-select="true"
            :disabled="!selectedClassificationVendors || selectedClassificationVendors.length == 0"
            :options="classificationSegments"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="form-group">
          <label>Sub Segment(s)</label>
          <multiselect
            v-model="selectedClassificationSubSegments"
            placeholder="Select SubSegment(s)"
            @input="subSegmentsChanged()"
            group-label="label"
            group-values="values"
            :group-select="true"
            :disabled="!selectedClassificationSegments || selectedClassificationSegments.length == 0"
            :options="classificationSubSegments"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="form-group">
          <label>Product(s)</label>
          <multiselect
            v-model="selectedProducts"
            placeholder="Select Products"
            @input="productsChanged()"
            group-label="label"
            group-values="values"
            :group-select="true"
            :disabled="!selectedClassificationSubSegments || selectedClassificationSubSegments.length == 0"
            :options="products"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="form-group" v-if="showRegionInput">
          <label>Region(s)</label>
          <div class="form-control mb-2 pl-2" v-if="regions.length > 0 && !addingRegion" style="border: 1px solid #dde6e9">
            <button type="button" class="badge bg-red text-white" style="cursor: pointer; margin: .166em .5em .166em 0;" v-for="(region, index) in regions" :key="index" @click="removeRegion(index)" v-tooltip="'Remove region'">
              <span class="mr-1">{{ region }}</span>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <input type="text" class="form-control" style="height: 40px;" v-model="selectedRegions" v-if="regions.length === 0 || addingRegion" :disabled="selectedClassificationSubSegments.length == 0 || selectedProducts.length == 0">
          <button v-if="addingRegion || regions.length === 0" type="button" class="btn btn-success mt-2 ml-0" :class="{ 'disabled': selectedClassificationSubSegments.length == 0 || selectedProducts.length == 0}" @click="addRegion()">
            Add Region
          </button>
          <button v-if="!addingRegion && regions.length > 0" type="button" class="btn btn-info mt-2" @click="addingRegion = true">
            New Region
          </button>
          <button v-if="addingRegion && regions.length !== 0" type="button" class="btn btn-primary mt-2" @click="addingRegion = false">
            Cancel
          </button>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-default">
          <div class="card-header pb-0 pt-4">
            <div v-if="clientsAffected.length > 0">
              <h3 class="text-center">{{ clientText }}</h3>
            </div>
            <div v-else>
              <h3 class="text-center">No clients found</h3>
            </div>
          </div>
          <div class="card-body pt-0">
            <hr />
            <div class="row" v-if="clientsAffected.length > 0">
              <div class="col-md-4">
                <div class="form-group">
                  <button
                    class="btn btn-success"
                    type="button"
                    @click="generateExportFile()"
                    :disabled="clientsAffected.length == 0"
                  >
                    Export
                  </button>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <div class="input-group">  
                      <input type="search" class="form-control" placeholder="Search by Client Number or Client Name..." v-model="searchTerm">
                      <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-bordered table-responsive" v-if="filteredClients.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Client Number</th>
                    <th>Client Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="client in filteredClients"
                    :key="client.clientNumber"
                  >
                    <td>{{ client.clientNumber }}</td>
                    <td v-if="client.type == 'Corporate'">
                      {{ client.companyname }}
                    </td>
                    <td v-else>{{ client.firstnames }} {{ client.lastname }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      selectedClassificationTypes: [],
      classificationTypes: [
        {
          label: "Select All",
          values: [],
        },
      ],
      selectedClassificationVendors: [],
      classificationVendors: [
        {
          label: "Select All",
          values: [],
        },
      ],
      selectedClassificationSegments: [],
      classificationSegments: [
        {
          label: "Select All",
          values: [],
        },
      ],
      selectedClassificationSubSegments: [],
      classificationSubSegments: [
        {
          label: "Select All",
          values: [],
        },
      ],
      selectedProducts: [],
      products: [
        {
          label: "Select All",
          values: [],
          productList: [],
        },
      ],
      selectedRegions: "",
      regions: [],
      clientsAffected: [],
      filteredClientsList: [],
      downloadToken: "",
      searchTerm: '',
      selectedProductNumber: [],
      addingRegion: false,
      showRegionInput: false
    };
  },
  created() {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    this.getClassificationTypes();
  },
  computed: {
    filteredClients() {
      this.filteredClientsList = this.clientsAffected;

      if (this.searchTerm) {
        this.filteredClientsList = this.filteredClientsList.filter((client) => 
              client.clientNumber.toString().includes(this.searchTerm) ||
              client.companyname.toLowerCase().includes(this.searchTerm.toLowerCase()) ||      
              client.firstnames.toLowerCase().includes(this.searchTerm.toLowerCase()) ||       
              client.lastname.toLowerCase().includes(this.searchTerm.toLowerCase())        
        );                
      };

      return this.filteredClientsList;
    },
    clientText() {
      return this.clientsAffected.length === 1 ? `${this.clientsAffected.length} client affected` : `${this.clientsAffected.length} clients affected`;
    }
  },
  watch: {
    selectedClassificationSegments() {
      if (this.selectedClassificationSegments.includes("Connectivity") && this.selectedClassificationSegments.length === 1 && this.selectedClassificationSegments !== null) {
        this.showRegionInput = true;
      } else {
        this.showRegionInput = false;
      }
    },
    selectedClassificationSubSegments() {         
      if (this.selectedClassificationSubSegments !== null) {
        this.showRegionInput = true;
      } else {
        this.showRegionInput = false;
      }
    }
  },
  methods: {
    getClassificationTypes: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/classifications/types`).then(
        (response) => {          
          this.classificationTypes[0].values = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching classification types", error);
        }
      );
    },
    getClassificationVendorsForTypes: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.post(`${hostUrl}products/classifications/vendors`, this.selectedClassificationTypes).then(
          (response) => {
            this.classificationVendors[0].values = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching classification vendors", error);
          }
        );
    },
    generateExportFile: function() {
      const classificationFilters = {
        types: this.selectedClassificationTypes,
        vendors: this.selectedClassificationVendors,
        segments: this.selectedClassificationSegments,
        subSegments: this.selectedClassificationSubSegments,
        productDescription: this.selectedProducts,
        productNumber: this.selectedProductNumber,
      };
     
      this.$swal({
        title: "Enter file name",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Export",
        input: "text",
      }).then((file) => {
        const filename = file.value.split(" ").join("_") + ".xls";
        const hostUrl = this.$config.aimsAPI;

        this.$http.post(`${hostUrl}clients/affectedclients/export`, classificationFilters, { responseType: "arraybuffer", }).then(
            (response) => {
              const contentType = response.headers.get("Content-Type");
              let blob = new Blob([response.data], { type: contentType, });

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = filename;
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
            },
            (error) => {
              console.error(error);
              this.showError("Error Exporting List", error);
            }
          );
      });
    },
    fetchClientsAffected: function() {
      if (!this.selectedClassificationTypes || this.selectedClassificationTypes.length == 0) {
        return;
      }

      if (!this.selectedClassificationVendors || this.selectedClassificationVendors.length == 0) {
        return;
      }

      if (!this.selectedClassificationSegments || this.selectedClassificationSegments.length == 0) {
        return;
      }

      if (!this.selectedClassificationSubSegments || this.selectedClassificationSubSegments.length == 0) {
        return;
      }

      if (!this.selectedProducts || this.selectedProducts.length == 0) {
        return;
      }

      const classificationFilters = {
        types: this.selectedClassificationTypes,
        vendors: this.selectedClassificationVendors,
        segments: this.selectedClassificationSegments,
        subSegments: this.selectedClassificationSubSegments,
        productDescription: this.selectedProducts,
        productNumber: this.selectedProductNumber,
      };

      const hostUrl = this.$config.aimsAPI;

      this.$http.post(`${hostUrl}clients/affectedclients`, classificationFilters).then(
          (response) => {
            this.clientsAffected = response.data;                        
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching affected clients", error);
          }
        );
    },
    getClientsAffectedByRegion: function(regions) {
      const hostUrl = this.$config.aimsAPI;
      const data = {
        types: this.selectedClassificationTypes,
        regions: regions,
        productNumber: this.selectedProductNumber,
        vendors: this.selectedClassificationVendors,
        segments: this.selectedClassificationSegments,
        subSegments: this.selectedClassificationSubSegments,
        productDescription: this.selectedProducts
      }     

      this.$http.post(`${hostUrl}clients/affected/regions`, data).then(
        (response) => {
          this.clientsAffected = response.data;
        },
        (error) => {
            console.error(error);
            this.showError("Error fetching affected clients by region", error);
        }
      );
    },
    typesChanged: function() {
      if (!this.selectedClassificationTypes || this.selectedClassificationTypes.length == 0) {
        return;
      }
      this.getClassificationVendorsForTypes();
    },
    vendorsChanged: function() {
      if (!this.selectedClassificationVendors || this.selectedClassificationVendors.length == 0) {
        this.selectedClassificationSegments = [];
        this.selectedClassificationSubSegments = [];
        return;
      }

      this.getSegmentOptions();
      this.segmentsChanged();
    },
    getSegmentOptions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.post(`${hostUrl}products/classifications/segments`, this.selectedClassificationVendors).then(
          (response) => {
            this.classificationSegments[0].values = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Classification Segments", error);
          }
        );
    },
    segmentsChanged: function() {
      if (!this.selectedClassificationSegments || this.selectedClassificationSegments.length == 0) {
        return;
      }

      if (!this.selectedClassificationVendors || this.selectedClassificationVendors.length == 0) {
        return;
      }

      this.getSubSegmentOptions();
    },
    getSubSegmentOptions: function() {
      const hostUrl = this.$config.aimsAPI;      

      this.$http.post(`${hostUrl}products/classifications/subsegments`, this.selectedClassificationSegments).then(
          (response) => {
            this.classificationSubSegments[0].values = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Classification Sub Segments", error);
          }
        );
    },
    subSegmentsChanged: function() {
      if (!this.selectedClassificationSubSegments || this.selectedClassificationSubSegments.length == 0) {
        this.products = [
          {
            label: "Select All",
            values: [],
            productList: [],
          },
        ];
        return;
      }

      this.getProductOptions();
    },
    getProductOptions: function() {
      const hostUrl = this.$config.aimsAPI;
      const classificationFilters = {
        types: this.selectedClassificationTypes,
        vendors: this.selectedClassificationVendors,
        segments: this.selectedClassificationSegments,
        subSegments: this.selectedClassificationSubSegments,
      };

      this.$http.post(`${hostUrl}products/classifications/all`, classificationFilters).then(
        (response) => {       
          let productData = response.data;
          let newProductObject = {};

          productData.forEach(product => {
            newProductObject = { 
              "description": product.description, 
              "productNumber": product.productNumber 
            };
            
            this.products[0].productList.push(newProductObject);
          });

          this.products[0].productList.forEach(p => {
            if (this.products[0].values.indexOf(p.description) === -1) {
              this.products[0].values.push(p.description);
            }
          });

        },
        (error) => {
          console.error(error);
          this.showError("Error fetching products", error);
        }
      );
    },
    productsChanged: function() {      
      this.products[0].productList.filter(pl => {
        if (this.selectedProducts.includes(pl.description) && this.selectedProductNumber.indexOf(pl.productNumber) === -1) {
          this.selectedProductNumber.push(pl.productNumber);
        }
      }); 
      this.clientsAffected = [];       
      this.fetchClientsAffected();
    },
    addRegion: function() {
      if (this.selectedClassificationSubSegments.length !== 0 && this.selectedProducts.length !== 0) {
        this.addingRegion = true
        if (this.selectedRegions != "") {
          this.regions.push(this.selectedRegions);
        }
        this.regions = [...new Set(this.regions)];
  
        this.getClientsAffectedByRegion(this.regions);
        this.selectedRegions = "";
        this.addingRegion = false
      }
    },
    removeRegion: function(region) {
      this.regions.splice(region, 1);
      this.getClientsAffectedByRegion(this.regions);
      return this.regions;
    }
  },
};
</script>

<style scoped>
.red-border {
  border: 2px solid red;
}

th {
    background-color: #3a3f51;
    color: #FFFFFF !important;
  
    position: sticky;
    top: 0;
    z-index: 2;

    padding: 1.2em .1em 1.2em .5em;
    width: 500px;
    font-size: .95em;

  }
</style>
