<template>
  <div>
    <div>
      <div>
        <div class="row row-spacing">
          <div class="form-group col-md-3">
            <label>Select Start Date</label>
            <div class="input-group">
              <!-- unfortunately month isn't supported in firefox-->      
              <!-- <input type="month" class="form-control" :min="minMonth" :max="maxMonth" v-model="dateStartSelected" @change="fetchStatement()"> -->
              <input type="date" class="form-control" :min="minDate" :max="maxDate" v-model="statementState.startDate" @change="fetchStatement()"> 
              <div class="input-group-append">
                  <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
              </div> 
            </div>
          </div>
          <div class="form-group col-md-3" v-allowed:view="['STAFF']">
            <label>Select End Date</label>
            <div class="input-group">
              <input type="date" class="form-control" :min="statementState.startDate" v-model="statementState.endDate" @change="fetchStatement()">
              <div class="input-group-append">
                  <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
              </div>
            </div>
          </div>

          <div class="form-group col-md mt-4">
            <div class="pull-right"> 
              
              <button
                v-if="client.paymenttype === 'Debited'"
                v-allowed:view="['STAFF']"
                class="btn btn-info mx-1"
                :disabled="!isAuthorizedUser" 
                @click="debitOrderDialog()"
              >
                Debit Order Exclusions
              </button>

              <button
                v-allowed:view="['STAFF']"
                class="btn btn-info mx-1"
                @click="emailDialog()"
              >
                Email Statement
              </button>
              <button
                class="btn btn-primary mx-1"
                @click="downloadStatement('PDF')"
              >
                Download PDF
              </button>
              <button
                class="btn btn-primary mx-1"
                @click="downloadStatementExcel()"
              >
                Download Excel
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="col-md-12 mt-4">
        <div class="row">
          <div class="card table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Reference</th>                  
                  <th>Description</th>
                  <th class="text-right">Debit</th>
                  <th class="text-right">Credit</th>
                  <th class="text-right">Balance</th>
                </tr>
              </thead>
              <tbody v-if="statement.lines.length > 0">
                <tr
                  v-for="(line, index) in statement.lines"
                  :class="line.type == 'CREDITNOTE' || line.type == 'INVOICE' ? 'table-hover' : ''"
                  :key="index"
                  @click="viewDocument(line)"
                  v-tooltip="line.type == 'CREDITNOTE' || line.type == 'INVOICE' ? `View ${line.type}` : ''"
                >
                  <td class="text-nowrap">{{ dateFormat(line.date) }}</td>
                  <td>
                    <span style="font-size: 16px !important" :class="line.type == 'CREDITNOTE' || line.type == 'INVOICE' ? 'btn btn-link p-0' : ''">
                      {{ line.typeDescription }}
                    </span>
                  </td>
                  <td>{{ line.reference }}</td>                   
                  <td>{{ line.description }}</td>
                  <td class="text-right">{{ currencyFormat(line.debit) }}</td>
                  <td class="text-right">{{ currencyFormat(line.credit) }}</td>
                  <td class="text-right">
                    {{ currencyFormat(line.balanceAmount) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7">No Statement available</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card card-default mt-2" v-if="statement.aging.length > 0">
        <div class="row text-center card-heading mt-2">
          <h4 :class="index == 3 ? 'col-md-3' : 'col-md-2'" v-for="aging, index in statement.aging" :key="aging.age">
             {{ aging.age }}
          </h4>
          <h4 class="col-md-3">
            Amount Due
          </h4>
        </div>
        <div class="card-body">
          <div class="row text-center">
            <div :class="index == 3 ? 'col-md-3' : 'col-md-2'" v-for="aging, index in statement.aging" :key="aging.age">
              {{ currencyFormat(aging.amount) }}
            </div>
            <div class="col-md-3">
              {{ currencyFormat(statement.total) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :title="'Email Contacts'"
      ref="emailDialogModal"
      size="modal-xl"
      save-text="Send Email"
      @save="sendEmail()"
      dismiss-text="Close"
    >
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="person in clientContacts"
                :key="person.personNumber"
                @click="contactClicked(person.emailAddress)"
              >
                <td>{{ person.firstNames }}</td>
                <td>{{ person.lastName }}</td>
                <td>{{ person.emailAddress }}</td>
                <td>
                  <input
                    type="checkbox"
                    :checked="isSelected(person.emailAddress)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="additionalContacts"
            placeholder="Comma separate additional email addresses"
          />
        </div>
      </div>
    </modal>
    <modal
      :title="'Debit Order Exclusions - Transactions to be excluded'"
      ref="debitOrderModal"
      size="modal-xl"
      save-text="Save"
      @save="saveDebitOrderExclusions()"
      dismiss-text="Close"
    >
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction Type</th>
                  <th>Reference</th>                  
                  <th>Description</th>
                  <th class="text-right">Transaction Amount</th>
                  <th>Excluded</th>
                </tr>
              </thead>
              <tbody v-if="excludedTransactions.length > 0">
                <tr
                  v-for="(line, index) in excludedTransactions"
                  :key="index"
                >
                  <td class="text-nowrap">{{ dateFormat(line.transaction.transactionDate) }}</td>
                  <td>
                      {{ getTypeDescription(line.transaction.type) }}
                  </td>
                  <td>{{ line.transaction.extRefrence }}</td>                   
                  <td>{{ line.transaction.description }}</td>
                  <td class="text-right">{{ currencyFormat(line.transaction.transactionAmount) }}</td>
                  <td>
                  <input
                    :disabled="line.disabled"
                    type="checkbox"
                    v-model="line.excluded"
                  />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7">No Transactions available</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
     
      <div class="row">
        <div class="col-md-12">
          <h4 class="pull-left">
              {{ currencyFormat(simulatedDO) }}
          </h4> 
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
                v-allowed:view="['STAFF']"
                class="btn btn-info mx-1 pull-left"
                @click="debitOrderSimulate()"
              >
                Simulate Debit Order amount
            </button>
        </div>
      </div>
    </modal>
   
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import { StatementState } from '@/stores/pages/Client/Statement.ts';

export default {
  components: {
    Modal,
  },
  data() {
    const statementState = StatementState();
    return {
      statementState,
      client: {},
      statement: {
        lines: [],
        aging: [],
      },
      excludedTransactions: [],
      simulatedDO: "",
      clientContacts: [],
      selectedContacts: [],
      additionalContacts: "",
      today: new Date().toLocaleString("en-ZA", {dateStyle: "long"}),
      formattedFrom: "",
      minDate:"",
      maxDate:""
    };
  },
  created() {
    this.$http
      .get(
        this.$config.aimsAPI +
          "documents/client/" +
          this.$route.params.clientNumber +
          "/statement/firstDate"
      )
      .then(
        (response) => {
          document.title = this.$route.params.clientNumber + " - Statement";
          this.minDate = response.data; //returns string in a usable format 2019-04-15
          var date = new Date();
          var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString()
                    .split("T")[0];
          this.maxDate = dateString; //get today's date in a usable format 2022-11-21
          this.statementState.initStartDate();
          this.fetchStatement();  
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Client Statement Dates", error);
        }
      );
  },
  computed: {
    isAuthorizedUser() {
        return this.userContainsPermission(['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF','MANAGER']);
    }
  },
  mounted() {
    if (this.userContainsPermission(["CLIENT"])) {
        this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Statement'); 
    }
  },
  methods: {
    fetchStatement() {

      if (this.statementState.startDate.substring(0, 4) < this.minDate.substring(0, 4)) {
        this.statementState.startDate = this.minDate;
        this.showWarningAlert("Can't select a date before: " + this.minDate);
      }

      this.$http
          .get(
            this.$config.aimsAPI +
              "documents/client/" +
              this.$route.params.clientNumber +
              "/statement?startDate=" +
              this.statementState.startDate +
              "&asOnDate=" +
              this.statementState.endDate
          )
          .then(
            (response) => {
              this.statement = response.data;

              //another call to get client
              this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber)
              .then((response) => {
                this.client = response.data;  
              }); 

            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Client Statement", error);
            }
          );
    },
    viewDocument(document) {
      if (document.type == "INVOICE" || document.type == "CREDITNOTE") {
        this.$router.push({
          name: "documentedit",
          params: {
            clientNumber: this.$route.params.clientNumber,
            documentNumber: document.reference,
          },
        });
      }
    },
    downloadStatement(type) {
      this.$http
        .get(
          this.$config.aimsAPI +
            "documents/client/" +
            this.$route.params.clientNumber +
            "/statement/download?contentType=" +
            type +
            "&startDate=" +
            this.statementState.startDate +
            "&asOnDate=" +
            this.statementState.endDate,
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Statement", error.data);
          }
        );
    },
    downloadStatementExcel() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "documents/client/" +
            this.$route.params.clientNumber +
            "/statement/download/excel?startDate=" +
            this.statementState.startDate,
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Statement", error.data);
          }
        );
    },
    emailDialog() {
      this.$http.get(this.$config.aimsAPI + 'clients/' + this.$route.params.clientNumber)
      .then(
        (response) => {
          this.client = response.data;

          this.$http
            .get(
              this.$config.aimsAPI +
                "documents/contacts/" +
                this.client.clientNumber
            )
            .then(
              (response) => {
                this.clientContacts = response.data;
                this.selectedContacts = [];
                this.$refs.emailDialogModal.open();
              },
              (error) => {
                console.error(error);
                this.showError("Error Fetching Client Contacts", error);
              }
            );
        }
      );              
    },
    debitOrderDialog() {
      if (this.statementState.startDate.substring(0, 4) < this.minDate.substring(0, 4)) {
        this.statementState.startDate = this.minDate;
        this.showWarningAlert("Can't select a date before: " + this.minDate);
      }
      this.$http
        .get(this.$config.aimsAPI + "documents/client/" + this.$route.params.clientNumber 
                                  + "/statement/exclusions?startDate=" + this.statementState.startDate 
                                  + "&asOnDate=" + this.statementState.endDate)
        .then(
          (response) => {
            this.excludedTransactions = response.data;
            console.log(this.excludedTransactions);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Client Excluded Transactions", error);
          }
      );
      this.$refs.debitOrderModal.open();
    },
    saveDebitOrderExclusions() {
      this.$http
          .put(
            this.$config.aimsAPI + "documents/client/" + this.$route.params.clientNumber +
              "/statement/exclusions",this.excludedTransactions
          )
          .then(
            (response) => {
              this.showSuccess("Saving Debit Order Exclusions");
            },
            (error) => {
              console.error(error);
              this.showError("Saving Debit Order Exclusions", error);
            }
          );
    },
    debitOrderSimulate() {
      this.$http
        .get(this.$config.aimsAPI + "documents/client/" + this.$route.params.clientNumber + "/statement/dosimulate")
        .then(
          (response) => {
            this.simulatedDO = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error trying to simulate Debit Order amount", error);
          }
      );
    },
    getTypeDescription : function(text) {
      let docText = text;
      switch (text) {
        case "INVOICE":
          docText = "Invoice";
          break;
        case "EFT_SETTLEMENT":
          docText = "EFT Payment";
          break;
        case "CASH_SETTLEMENT":
          docText = "Cash Payment";
          break;
        case "CC_SETTLEMENT":
          docText = "Credit Card Payment";
          break;
      }
      return docText;
    },
    sendEmail() {
      var addArray = this.additionalContacts.split(",");
      addArray.forEach((e) => {
        this.contactClicked(e);
      });

      if (this.selectedContacts.length > 0) {
        this.$refs.emailDialogModal.isLoading();
        this.$http
          .put(
            this.$config.aimsAPI +
              "documents/client/" +
              this.$route.params.clientNumber +
              "/statement/email?startDate=" +
              this.statementState.startDate +
              "&asOnDate=" +
              this.statementState.endDate,
            this.selectedContacts
          )
          .then(
            (response) => {
              this.showSuccess("Email Sent");
              this.$refs.emailDialogModal.close();
            },
            (error) => {
              console.error(error);
              this.showError("Error Emailing Statement", error);
            }
          )
          .finally(() => {
            this.$refs.emailDialogModal.isLoading();
          });
      }
      this.additionalContacts = "";
      this.selectedContacts = [];
    },
    contactClicked(emailAddress) {
      if (emailAddress.length > 0) {
        const indx = this.selectedContacts.indexOf(emailAddress);
        if (indx >= 0) {
          this.selectedContacts.splice(indx, 1);
        } else {
          this.selectedContacts.push(emailAddress);
        }
      }
    },
    isSelected(emailAddress) {
      const indx = this.selectedContacts.findIndex((p) => p === emailAddress);
      if (indx >= 0) {
        return true;
      } else {
        return false;
      }
    }
  },
};
</script>

<style scoped>
.mx-datepicker {
  display: block !important;
}

.table>thead>tr>th {
  background-color: #3a3f51;
  color: #FFFFFF; 
  padding: 1em;

  top: 0;
  z-index: 2;
  position: sticky;
} 

.custom-line {
  position: relative;
  margin: -12;
  z-index: 3;
}

tfoot {
  background-color: #FFFFFF;

  bottom: 0;
  z-index: 2;
  position: sticky;
} 

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}
</style>
