<template>
    <service-screen-layout :parts="parts">
            <template v-slot:apn>
                <IonlineApn :apn="saleDefinition.definition.apn"/>
            </template>
            <template v-slot:realm>
                <IonlineApnRealm 
                    :apn="saleDefinition.definition.apn"
                    :realms="saleDefinition.definition.apn.apnRealm"
                    :realmObj="saleDefinition.supportingObjs.apnRealm"
                    :providers="saleDefinition.supportingEnums.ApnRealm.provider"
                    @reloadSale="getSaleDefinition"
                />
            </template>
            <template v-slot:sims>
                <IonlineSimManagement 
                    :apn="saleDefinition.definition.apn"
                    :changes="saleDefinition.definition.apn.apnApnSimChange"
                    :changeObj="saleDefinition.supportingObjs.apnApnSimChange"
                    @reloadSale="getSaleDefinition"
                />
            </template>
            <template v-slot:usage>
                <IonlineDataUsage />
            </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout.vue';

import IonlineApn from '@/components/ObjParts/SingleCaptured/IonlineApn.vue';
import IonlineApnRealm from '@/components/ObjParts/MultipleCaptured/IonlineApnRealm.vue';
import IonlineSimManagement from '@/components/ObjParts/MultipleCaptured/IonlineSimManagement.vue';
import IonlineDataUsage from '@/components/ObjParts/MultipleLoaded/IonlineDataUsage.vue';

export default {
    components: {
        ServiceScreenLayout,
        IonlineApn,
        IonlineApnRealm,
        IonlineSimManagement,
        IonlineDataUsage
    },
    data() {
        return {
            parts: [
                {
                    name: "apn",
                    icon: "fa fa-wifi",
                    displayName: "APN Service",
                    permissions: ["*"],
                },
                {
                    name: "realm",
                    icon: "fa fa-globe",
                    displayName: "Realm",
                    permissions: ["*"],
                },
                {
                    name: "sims",
                    icon: "fa fa-sim-card",
                    displayName: "SIM Management",
                    permissions: ["*"],
                },
                {
                    name: "usage",
                    icon: "fa fa-chart-simple",
                    displayName: "Usage Data",
                    permissions: ["*"],
                },
            ],
            saleNumber: null,
            saleDefinition: {
                definition: {
                    apn: {
                        apnPackage: {},
                        apnSimsPackage: {},
                        apnApnBulkUsage: [],
                        apnApnSimChange: [],
                        apnRealm: []
                    }
                },
                supportingEnums: {
                    ApnRealm: {
                        provider: []
                    }
                },
                supportingObjs: {
                    apnApnSimChange: {},
                    apnRealm: {}
                }
            },
        }
    },
    mounted() {
        this.saleNumber = this.$root.$route.params.saleNumber;
        this.getSaleDefinition();
    },
    methods: {
        getSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}sales/definition/sale/${this.saleNumber}`).then(
                (response) => {                    
                    this.saleDefinition = response.data;
                    console.log(this.saleDefinition);
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Sale Definition", error);
                }
            )
            
        }
    }  
}
</script>

<style lang="scss" scoped>

</style>