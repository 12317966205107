<template>
    <div>
       <div>
            <ul class="nav nav-pills nav-fill" role="tablist">
                <li class="nav-item">
                    <a 
                        href="#data" 
                        class="nav-link active"
                        id="data-tab"
                        role="tab"
                        aria-controls="data"
                        aria-selected="true"
                        data-toggle="tab"
                    >
                        <i class="fa fa-dropbox"></i>
                        Data Packages
                    </a>
                </li>
                <li class="nav-item">
                    <a 
                        href="#sims" 
                        id="sims-tab"
                        class="nav-link"
                        role="tab"
                        aria-controls="sims"
                        aria-selected="true"
                        data-toggle="tab"
                    >
                        <i class="fa fa-dropbox"></i>
                        SIM Packages
                    </a>
                </li>
            </ul>
       </div>
       <div class="tab-content">
            <div class="tab-pane active" id="data" role="tabpanel" aria-labelledby="data-tab">
                <ApnDataPricing 
                :productNumber="productNumber"
                :productSupplier="productSupplier"
                :userEditRight="userEditRight"
                />
            </div>
            <div class="tab-pane" id="sims" role="tabpanel" aria-labelledby="sims-tab">
                <ApnSimsPricing
                :productNumber="productNumber"
                :productSupplier="productSupplier"
                :userEditRight="userEditRight"
                />
            </div>
       </div>       
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ApnDataPricing from "@/components/Admin/ApnDataPricing";
import ApnSimsPricing from "@/components/Admin/ApnSimsPricing";

export default {
    extends: PriceFunctions,
    components: {
        ApnSimsPricing,
        ApnDataPricing
    },
    props: {
        productNumber: {
            type: Number,
            required: true,
        },
        productSupplier: {
            type: String,
            required: true,
        },
        userEditRight: {
            type: Boolean,
            required: true,
        },
    }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}
.nav-pills .nav-link {
  background-color: #f3f2ee;
}
a {
  color: rgba(0, 0, 0, 0.6);
}
</style>