<template>
  <div>
    <form class="row" novalidate>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Address Type') }"
            >
              <label>Address Type *</label>
              <select
                name="Address Type"
                v-validate="'required'"
                class="form-control"
                v-model="address.addressType"
                @change="addressChanged()"
              >
                <option
                  :value="addressType.name"
                  v-for="addressType in addressTypes"
                  :key="addressType.name"
                >
                  {{ addressType.description }}
                </option>
              </select>
              <span class="has-error" v-if="errors.has('Address Type')">{{
                errors.first("Address Type")
              }}</span>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Sub Premise</label>
              <input
                placeholder="Sub Premise"
                name="Subpremise"
                class="form-control"
                v-model="address.subpremise"
                @change="addressChanged()"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <GoogleMapsAddress 
              :addressStructure="addressStructure"
              @addressChanged="addressChanged"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import GoogleMapsAddress from './GoogleMapsAddress.vue';

export default {
  components: {
    GoogleMapsAddress
  },
  props: {
    addressStructure: {
      type: Object,
      required: false,
    },
    addressTypes: {
      type: Array,
      required: false,
    },
  },
  watch: {
    addressStructure: {
      handler(to, from) {
        this.address = this.deepCopyObject(this.addressStructure);
      },
      immediate: true,
    },
    addressTypes: {
      handler(to, from) {
        //set default address type to first in list
        if (
          to &&
          to.length > 0 &&
          (!this.address.addressType ||
            this.address.addressType === "" ||
            this.address.addressType.includes("None"))
        ) {
          this.address.addressType = this.addressTypes[0].name;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      address: {},
    };
  },
  methods: {
    addressChanged(address) {
      this.$validator.validateAll().then((result) => {
        this.$emit("addressValid", result);
      });      

      if (address) {
        address.addressType = this.address.addressType;
        this.address = address;
        this.$emit("addressChanged", address);
      } else {
        this.$emit("addressChanged", this.address);
      }   
    },
  },
};
</script>

<style>
</style>
